import React from 'react';
import { graphql } from 'gatsby';
import Homepage from '../components/Views/Homepage/Homepage';

type Props = {
  data: {
    allPost: any;
    [key: string]: string;
  };
};

export const query = graphql`
  query {
    allPost(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        slug
        title
        tagLine
        date(formatString: "DD.MM.YYYY")
        tags {
          name
          slug
        }
      }
    }
  }
`;

export default ({ data }: Props) => {
  const { allPost } = data;

  return <Homepage posts={allPost.nodes} />;
};
