import React from 'react';
import Layout from '../../Layout/Layout';
import * as styles from './Homepage.module.css';

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
};

const Homepage = ({ posts }: PostsProps) => (
  <>
    <Layout
      currentPage={{ name: 'home', title: 'index.js', footer: 'JavaScript' }}
    >
      <div className={styles.hello}>
        <pre className={styles.pre}>
          <code className={styles.title}>
            {`
                     lllllll
                     l:::::l
                     l:::::l
                     l:::::l              ///                ///
    eeeeeeeeeeee      l::::l     ooooooooooo        ooooooooooo          nnnnnnn           eeeeeeeeeeee
  ee:::::::::::::e    l::::l   oo:::::::::::oo    oo:::::::::::oo    ::nn:::::::nn       ee::::::::::::e
 e::::::eeeee:::::e   l::::l  o:::::::::::::::o  o:::::::::::::::o  n:::::::::::::n    e::::::eeeee:::::e
e::::::e     e:::::e  l::::l  o:::::ooooo:::::o  o:::::ooooo:::::o  n::::::::::::::n  e::::::e     e:::::e
e:::::::eeeee::::::e  l::::l  o::::o     o::::o  o::::o     o::::o  n:::::nnnn:::::n  e:::::::eeeee::::::e
e:::::::::::::::::e   l::::l  o::::o▓▓░  o::::o  o::::o▓▓░  o::::o  n::::n    n::::n  e:::::::::::::::::e
e::::::eeeeeeeeeee    l::::l  o::::o███  o::::o  o::::o███  o::::o  n::::n    n::::n  e::::::eeeeeeeeeee
e:::::::e             l::::l  o::::o     o::::o  o::::o     o::::o  n::::n    n::::n  e:::::::e
e::::::::e           l::::::l o:::::ooooo:::::o  o:::::ooooo:::::o  n::::n    n::::n  e::::::::e
 e::::::::eeeeeeeee  l::::::l o:::::::::::::::o  o:::::::::::::::o  n::::n    n::::n   e::::::::eeeeeeeee
  ee::::::::::::::e  l::::::l  oo:::::::::::oo    oo:::::::::::oo   n::::n    n::::n    ee::::::::::::::e
    eeeeeeeeeeeeeee  llllllll    ooooooooooo        ooooooooooo     nnnnnn    nnnnnn      eeeeeeeeeeeeeee
  `}
          </code>
          <code className={styles.subtitle}>
            {`
****************************************************************************************************
*  ____ ____ ____ ____ ____ ____ ____ ____ _________ ____ ____ ____ ____ ____ ____ ____ ____ ____  *
* ||c |||o |||m |||p |||u |||t |||e |||r |||       |||s |||c |||i |||e |||n |||t |||i |||s |||t || *
* ||__|||__|||__|||__|||__|||__|||__|||__|||_______|||__|||__|||__|||__|||__|||__|||__|||__|||__|| *
* |/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/_______\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\| *
*                                                                                                  *
****************************************************************************************************
    `}
          </code>
        </pre>
        <div className={styles.description}>
          I build large-scale web applications since 2009.
        </div>
      </div>
      <div className={styles.fallbackHello}>
        <div className={styles.title}>eloone</div>
        <div className={styles.subtitle}>Computer Scientist</div>
        <div className={styles.description}>
          I build large-scale web applications since 2009.
        </div>
      </div>
    </Layout>
  </>
);

export default Homepage;
/*

███    █▄  ▀███████████               ███ ███    █▄  ███    ███    █▄  ███   ███     ███     ███           ███     ███     
███    ███ ███    ███ ███   ███   ███   ███        ███    ███     ███       ███
●
 @@@@@@@   @@@@@@   @@@@@@@@@@   @@@@@@@   @@@  @@@  @@@@@@@  @@@@@@@@  @@@@@@@       @@@@@@    @@@@@@@  @@@  @@@@@@@@  @@@  @@@  @@@@@@@  @@@   @@@@@@   @@@@@@@  
@@@@@@@@  @@@@@@@@  @@@@@@@@@@@  @@@@@@@@  @@@  @@@  @@@@@@@  @@@@@@@@  @@@@@@@@     @@@@@@@   @@@@@@@@  @@@  @@@@@@@@  @@@@ @@@  @@@@@@@  @@@  @@@@@@@   @@@@@@@  
!@@       @@!  @@@  @@! @@! @@!  @@!  @@@  @@!  @@@    @@!    @@!       @@!  @@@     !@@       !@@       @@!  @@!       @@!@!@@@    @@!    @@!  !@@         @@!    
!@!       !@!  @!@  !@! !@! !@!  !@!  @!@  !@!  @!@    !@!    !@!       !@!  @!@     !@!       !@!       !@!  !@!       !@!!@!@!    !@!    !@!  !@!         !@!    
!@!       @!@  !@!  @!! !!@ @!@  @!@@!@!   @!@  !@!    @!!    @!!!:!    @!@!!@!      !!@@!!    !@!       !!@  @!!!:!    @!@ !!@!    @!!    !!@  !!@@!!      @!!    
!!!       !@!  !!!  !@!   ! !@!  !!@!!!    !@!  !!!    !!!    !!!!!:    !!@!@!        !!@!!!   !!!       !!!  !!!!!:    !@!  !!!    !!!    !!!   !!@!!!     !!!    
:!!       !!:  !!!  !!:     !!:  !!:       !!:  !!!    !!:    !!:       !!: :!!           !:!  :!!       !!:  !!:       !!:  !!!    !!:    !!:       !:!    !!:    
:!:       :!:  !:!  :!:     :!:  :!:       :!:  !:!    :!:    :!:       :!:  !:!         !:!   :!:       :!:  :!:       :!:  !:!    :!:    :!:      !:!     :!:    
 ::: :::  ::::: ::  :::     ::    ::       ::::: ::     ::     :: ::::  ::   :::     :::: ::    ::: :::   ::   :: ::::   ::   ::     ::     ::  :::: ::      ::    
 :: :: :   : :  :    :      :     :         : :  :      :     : :: ::    :   : :     :: : :     :: :: :  :    : :: ::   ::    :      :     :    :: : :       : 
 */

/*
          ***                                                  
           ***                                                 
            **                                                 
            **                                                 
            **       ****       ****                           
   ***      **      * ***  *   * ***  * ***  ****       ***    
  * ***     **     *   ****   *   ****   **** **** *   * ***   
 *   ***    **    **    **   **    **     **   ****   *   ***  
**    ***   **    **    **   **    **     **    **   **    *** 
********    **    **    **   **    **     **    **   ********  
*******     **    **    **   **    **     **    **   *******   
**          **    **    **   **    **     **    **   **        
****    *   **     ******     ******      **    **   ****    * 
 *******    *** *   ****       ****       ***   ***   *******  
  *****      ***                           ***   ***   *****   


          ,dPYb,                                                 
          IP'eYb                                                 
          I8  8I                                                 
          I8  8'                                                 
   ***   I8 dP    ,ggggg,      ,ggggg,     ,ggg,,ggg,    ,ggg,  
 *** ***  I8dP    dP"  "Y8ggg  dP"  "Y8ggg ,8" "8P" "8,  i8" "8i 
 *** ***  I8P    i8'    ,8I   i8'    ,8I   I8   8I   8I  I8, ,8I 
 eeeeeee ,d8b,_ ,d8,   ,d8'  ,d8,   ,d8'  ,dP   8I   Yb, eYbadP' 
888P"Y8888P'"Y88P"Y8888P"    P"Y8888P"    8P'   8I   eY8888P"Y888
                                                                 
 ____ ____ ____ ____ ____ ____ 
||e |||l |||o |||o |||n |||e ||
||__|||__|||__|||__|||__|||__||
|/__\|/__\|/__\|/__\|/__\|/__\|
*/

/*

                   lllllll                                                                         
                   l:::::l                                                                         
                   l:::::l                                                                         
                   l:::::l                                                                         
    eeeeeeeeeeee    l::::l    ooooooooooo      ooooooooooo         nnnnnnnn        eeeeeeeeeeee    
  ee::::::::::::ee  l::::l  oo:::::::::::oo  oo:::::::::::oo   :nn::::::::nn    ee::::::::::::ee  
 e::::::eeeee:::::eel::::l o:::::::::::::::oo:::::::::::::::o  :::::::::::::nn  e::::::eeeee:::::ee
e::::::e     e:::::el::::l o:::::ooooo:::::oo:::::ooooo:::::o  :::::::::::::::ne::::::e     e:::::e
e:::::::eeeee::::::el::::l o::::o     o::::oo::::o     o::::o  n:::::nnnn:::::ne:::::::eeeee::::::e
e:::::::::::::::::e l::::l o::::o     o::::oo::::o     o::::o  n::::n    n::::ne:::::::::::::::::e 
e::::::eeeeeeeeeee  l::::l o::::o     o::::oo::::o     o::::o  n::::n    n::::ne::::::eeeeeeeeeee  
e:::::::e           l::::l o::::o     o::::oo::::o     o::::o  n::::n    n::::ne:::::::e           
e::::::::e         l::::::lo:::::ooooo:::::oo:::::ooooo:::::o  n::::n    n::::ne::::::::e          
 e::::::::eeeeeeee l::::::lo:::::::::::::::oo:::::::::::::::o  n::::n    n::::n e::::::::eeeeeeee  
  ee:::::::::::::e l::::::l oo:::::::::::oo  oo:::::::::::oo   n::::n    n::::n  ee:::::::::::::e  
    eeeeeeeeeeeeee llllllll   ooooooooooo      ooooooooooo     nnnnnn    nnnnnn    eeeeeeeeeeeeee
*/
